import { createReducer } from 'redux-starter-kit';
import { setProfileInfo } from 'store/actions/auth';

const initialState = {
  profile: {},
  credential: {},
};

export const profileReducer = createReducer(initialState, {
  [setProfileInfo]: (state, action) => ({
    ...state,
    profile: action.payload.data,
  }),
});
