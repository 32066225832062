const errorMessages = {
  email_exists: 'Bu mail ilə artıq qeydiyyat edilib.',
  company_name_exists: 'Bu adla şirkət artıq sistemdə qeydiyyatdan keçmişdir.',
  catalog_name_is_already_exists: 'Bu kataloq adı artıq mövcuddur',
  transaction_catalog_is_already_exists: 'Bu xərc maddəsi adı artıq mövcuddur',
  transaction_item_is_already_exists: 'Bu xərc adı artıq mövcuddur',
  account_is_not_active: 'Bu hesab aktiv deyil',
  product_code_exists: 'Bu kod artıq təyin edilib',
};

export default errorMessages;
