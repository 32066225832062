import { createReducer } from 'redux-starter-kit';
import { apiError, apiStart, apiEnd } from 'store/actions/api';
import { resetUserInfo, setLoggedStatus } from 'store/actions/auth';

const initialState = {
  data: undefined,
  apiError: undefined,
  isLoading: false,
  logged: false,
};

export const authReducer = createReducer(initialState, {
  [apiStart]: (state, action) => {
    if (action.payload === 'checkPartner') {
      return {
        ...state,
        isLoading: true,
      };
    }
  },
  [apiEnd]: (state, action) => {
    if (action.payload === 'checkPartner') {
      return {
        ...state,
        isLoading: false,
      };
    }
  },

  [apiError]: (state, action) => ({
    ...state,
    apiError: action.payload.message,
  }),

  [setLoggedStatus]: (state, action) => ({
    ...initialState,
    logged: action.payload,
  }),

  [resetUserInfo]: () => initialState,
});
