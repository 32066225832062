import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import { cookies } from 'utils/cookies';

export const setUserInfo = createAction('setUserInfo');
export const setPartnershipSender = createAction('setPartnershipSender');
export const resetUserInfo = createAction('resetUserInfo');
export const setLoggedStatus = createAction('setLoggedStatus');

export const setProfileInfo = createAction('setProfileInfo');

const resetCookies = () => {
  cookies.remove('_TKN_');
  cookies.remove('username');
  cookies.remove('dvc');
};

const saveData = data => {
  console.log("🚀 ~ file: index.js ~ line 19 ~ data", data)
  
  cookies.set('_TKN_', data?.data?.accessToken);
  cookies.set('username', data?.data?.username);
  cookies.set('dvc', data?.data?.deviceToken);
};

export function login({ username, password }, onSuccessCallback) {
  resetCookies();
  const deviceToken = cookies.get('dvc') || '';
  return apiAction({
    url: '/admin/login',
    method: 'POST',
    data: { username, password, deviceToken },
    onSuccess: data => dispatch => {
      dispatch(setProfileInfo(data));
      saveData(data);
      if (onSuccessCallback) onSuccessCallback();
    },
    label: 'auth',
    showErrorToast: false,
  });
}

export function checkToken(dispatch, onSuccessCallback) {
  const username = cookies.get('username');
  const deviceToken = cookies.get('dvc');
  const accessToken = cookies.get('_TKN_');
  if (username && deviceToken && accessToken) {
    dispatch(setLoggedStatus(true));
    if (onSuccessCallback) onSuccessCallback();
  }
}

export function setPassword(
  url,
  data,
  successCallback = () => {},
  failureCallback = () => {}
) {
  return apiAction({
    url,
    method: 'POST',
    data,
    onSuccess: () => () => {
      successCallback();
    },
    onFailure: params => () => {
      failureCallback(params);
    },
    label: 'setPassword',
    showErrorToast: false,
  });
}
