import { createReducer } from 'redux-starter-kit';
import {
  setModules,
  setServices,
  setSpecialParametrs,
} from 'store/actions/settings/products';

export const productsReducer = createReducer(
  {
    productTypes: [],
    modules: [],
    specialParametrs: [],
    services: [],
    isLoading: false,
    actionLoading: false,
  },
  {
    [setModules]: (state, action) => ({
      ...state,
      modules: action.payload.data,
    }),
    [setServices]: (state, action) => ({
      ...state,
      services: action.payload.data,
    }),
    [setSpecialParametrs]: (state, action) => ({
      ...state,
      specialParametrs: action.payload.data,
    }),
  }
);
