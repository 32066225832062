import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import { ErrorMessage } from 'utils';
import { toast } from 'react-toastify';

export const setRejectReasons = createAction('setRejectReasons');

export function fetchRejectReasons(onSuccessCallback = () => {}) {
  return apiAction({
    url: '/admin/tenant-rejection-reasons',
    onSuccess: data => dispatch => {
      dispatch(setRejectReasons(data));
      onSuccessCallback(data);
    },
    label: 'reasonsType',
  });
}

export function createReasons(index, name) {
  return apiAction({
    url: '/admin/tenant-rejection-reasons',
    method: 'POST',
    data: { name, type: 1 },
    onSuccess: fetchRejectReasons,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showErrorToast: false,
    showToast: true,
    label: 'reasonsType',
  });
}

export function editReasons(id, name) {
  return apiAction({
    url: `/admin/tenant-rejection-reasons/${id}`,
    method: 'PUT',
    data: { name, type: 1 },
    onSuccess: fetchRejectReasons,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showErrorToast: false,
    showToast: true,
    label: 'reasonsType',
  });
}

export function deleteReasons(id) {
  return apiAction({
    url: `/admin/tenant-rejection-reasons/${id}`,
    method: 'DELETE',
    onSuccess: fetchRejectReasons,
    showToast: true,
    label: 'reasonsType',
  });
}

// SubscriptionReasons
export function createReasonsSub(index, name) {
  return apiAction({
    url: '/admin/tenant-rejection-reasons',
    method: 'POST',
    data: { name, type: 2 },
    onSuccess: fetchRejectReasons,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showErrorToast: false,
    showToast: true,
    label: 'reasonsType',
  });
}

export function editReasonsSub(id, name) {
  return apiAction({
    url: `/admin/tenant-rejection-reasons/${id}`,
    method: 'PUT',
    data: { name, type: 2 },
    onSuccess: fetchRejectReasons,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showErrorToast: false,
    showToast: true,
    label: 'reasonsType',
  });
}
