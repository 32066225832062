/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense } from 'react';
import { useDispatch, connect } from 'react-redux';
import { checkToken } from 'store/actions/auth';
import { showPreloader } from 'utils/preloaderControl';

const loadAuthorizedApp = () => import('./AuthorizedApp');

const AuthorizedApp = lazy(loadAuthorizedApp);
const UnAuthorizedApp = lazy(() => import('./UnAuthorizedApp'));

const FallBack = () => {
  showPreloader();
  return null;
};

function App(props) {
  const { checkToken, checkTokenLoading, logged } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    checkToken(dispatch);
  }, []);

  if (checkTokenLoading) {
    return <FallBack />;
  }

  if (logged) {
    return (
      <Suspense fallback={<FallBack />}>
        <AuthorizedApp />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<FallBack />}>
      <UnAuthorizedApp />
    </Suspense>
  );
}

const mapStateToProps = state => ({
  checkTokenLoading: !!state.loadings.checkToken,
  logged: state.authReducer.logged,
});

export default connect(
  mapStateToProps,
  { checkToken }
)(App);
