import React from 'react';
import { Input } from 'antd';
import styles from './styles.module.scss';

export const ProSearch = props => {
  const {
    onSearch,
    label,
    placeholder = 'Yazın',
    allowClear = true,
    size = 'large',
    autoComplete = 'off',
    ...rest
  } = props;
  return (
    <Input.Search
      placeholder={placeholder}
      onSearch={onSearch}
      allowClear={allowClear}
      className={styles.search}
      autoComplete={autoComplete}
      size={size}
      {...rest}
    />
  );
};
