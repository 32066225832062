import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import { ErrorMessage } from 'utils';
import { toast } from 'react-toastify';

export const setApps = createAction('setApps');

export function fetchApps(onSuccessCallback = () => {}) {
  return apiAction({
    url: '/admin/applications',
    onSuccess: data => dispatch => {
      dispatch(setApps(data));
      onSuccessCallback(data);
    },
    label: 'features',
  });
}

export function createApps(data, onSuccess = () => {}) {
  return apiAction({
    url: '/admin/applications',
    method: 'POST',
    data,
    label: 'cashbox',
    onSuccess: params => dispatch => {
      dispatch(fetchApps(params));
      dispatch(onSuccess(data));
    },
  });
}

export function editApps(id, data, onSuccess = () => {}) {
  return apiAction({
    url: `/admin/applications/${id}`,
    method: 'PUT',
    data,
    onSuccess: params => dispatch => {
      dispatch(fetchApps(params));
      dispatch(onSuccess(data));
    },
    showErrorToast: false,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showToast: true,
    label: 'features',
  });
}

export function deleteApps(id) {
  return apiAction({
    url: `/admin/applications/${id}`,
    method: 'DELETE',
    onSuccess: fetchApps,
    showToast: true,
    label: 'features',
  });
}
