import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import { filterQueryResolver } from 'utils';

export const setBalance = createAction('setBalance');
export const setBalanceCount = createAction('setBalanceCount');
export const setTenantPayments = createAction('setTenantPayments');

export const fetchTenentFeatureBalance = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/report/tenant-balance?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: data => dispatch => {
      dispatch(setBalance(data));
      dispatch(getBalanceTotalCount({ query }));
    },
    attribute: {},
  });
};

export function getBalanceTotalCount({ query }) {
  const url = `/admin/report/tenant-balance/count?status=3&${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setBalanceCount,
    attribute: {},
  });
}

export const fetchTenantPayments = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenant-payments?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setTenantPayments,
    attribute: {},
  });
};
