export function ErrorMessage(error) {
  let errorMessage = '';
  if (
    error.error.response.data.error.message === 'This email is already exists.'
  ) {
    errorMessage = 'Bu e-poçt ünvanı artıq mövcuddur';
  } else if (
    error.error.response.data.error.errors.message ===
    'This feature is already exists.'
  ) {
    errorMessage = 'Bu dəyər artıq mövcuddur.';
  } else if (
    error.error.response.data.error.errors.message ===
    'This reason already exists.'
  ) {
    errorMessage = 'Bu dəyər artıq mövcuddur.';
  } else if (error.error.response.data.error.line === 217) {
    errorMessage = '3 simvoldan az ola bilməz ';
  } else if (error.error.response.data.error.line === 189) {
    errorMessage = 'Dəyəri qeyd olunmuş, xidmətin adını qeyd edin.';
  }

  return errorMessage;
}
