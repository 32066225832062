import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import { ErrorMessage } from 'utils';
import { toast } from 'react-toastify';

export const setModules = createAction('setModules');
export const setServices = createAction('setServices');
export const setSpecialParametrs = createAction('setSpecialParametrs');

export function fetchProducts(onSuccessCallback = () => {}) {
  return apiAction({
    url: '/admin/features',
    onSuccess: data => dispatch => {
      dispatch(setModules(data));
      dispatch(setServices(data));
      dispatch(setSpecialParametrs(data));
      onSuccessCallback(data);
    },
    label: 'features',
  });
}

export function createProducts(name, defaultPrice) {
  return apiAction({
    url: '/admin/features',
    method: 'POST',
    data: { name, defaultPrice },
    onSuccess: fetchProducts,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showToast: true,
    label: 'features',
  });
}

export function editProducts(id, name, defaultPrice) {
  return apiAction({
    url: `/admin/features/${id}`,
    method: 'PUT',
    data: { name, defaultPrice },
    onSuccess: fetchProducts,
    showErrorToast: false,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showToast: true,
    label: 'features',
  });
}

export function deleteProducts(id) {
  return apiAction({
    url: `/admin/features/${id}`,
    method: 'DELETE',
    onSuccess: fetchProducts,
    showToast: true,
    label: 'features',
  });
}
