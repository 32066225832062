import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './styles.module.scss';

export function NavigationLink({ path, icon, children, link }) {
  return (
    <li className={styles.item}>
      {link ? (
        <a href={path} className={styles.link}>
          <img
            width={33}
            height={32}
            src={icon}
            alt={children}
            className={styles.icon}
          />
          <span className={styles.linkText}>{children}</span>
        </a>
      ) : (
        <NavLink
          to={path}
          className={styles.link}
          activeClassName={styles.active}
        >
          <img src={icon} alt={children} className={styles.icon} />
          <span className={styles.linkText}>{children}</span>
        </NavLink>
      )}
    </li>
  );
}

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  link: PropTypes.bool,
};

function Navigation(props) {
  const { tenants } = props;

  return (
    <nav className={styles.nav}>
      <ul className={styles.ul}>
        {/* <NavigationLink path="/dashboard" icon="/img/icons/dashboard2.svg">
          Nəzarət Paneli
        </NavigationLink> */}

        {tenants?.role === 4 ? (
          <NavigationLink path="/users" icon="/img/icons/employees.svg">
            İstifadəçilər
          </NavigationLink>
        ) : null}

        <NavigationLink path="/DemoTab" icon="/img/icons/contacts.svg">
          Demo
        </NavigationLink>

        <NavigationLink path="/registry" icon="/img/icons/stock.svg">
          Qeydiyyat
        </NavigationLink>

        {tenants?.role === 2 ? null : (
          <NavigationLink path="/finance" icon="/img/icons/maliyye.svg">
            Maliyyə
          </NavigationLink>
        )}
      </ul>
    </nav>
  );
}

export const MainNavigation = connect(
  null,
  null
)(Navigation);
