import { createReducer } from 'redux-starter-kit';
import {
  setBalance,
  setBalanceCount,
  setTenantPayments,
} from 'store/actions/finance/balance/';

export const financeReducer = createReducer(
  {
    balance: [],
    paymentTn: [],
    count: 0,
  },
  {
    [setBalance]: (state, action) => ({
      ...state,
      balance: action.payload.data,
    }),
    [setBalanceCount]: (state, action) => ({
      ...state,
      count: action.payload.data,
    }),
    [setTenantPayments]: (state, action) => ({
      ...state,
      paymentTn: action.payload.data,
    }),
  }
);
