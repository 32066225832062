import { createReducer } from 'redux-starter-kit';
import { setApps } from 'store/actions/settings/apps';

export const appsReducer = createReducer(
  {
    apps: [],
  },
  {
    [setApps]: (state, action) => ({
      ...state,
      apps: action.payload.data,
    }),
  }
);
