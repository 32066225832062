import { createReducer } from 'redux-starter-kit';
import { setRejectReasons } from 'store/actions/settings/rejectReasons';
import { apiStart, apiEnd } from 'store/actions/api';

export const reasonsReducer = createReducer(
  {
    reasonsData: [],
    isLoading: false,
    actionLoading: false,
  },
  {
    [apiStart]: (state, action) => {
      if (action.payload === 'reasonsType') {
        return {
          ...state,
          isLoading: true,
        };
      }
    },
    [apiEnd]: (state, action) => {
      if (action.payload === 'reasonsType') {
        return {
          ...state,
          isLoading: false,
        };
      }
    },
    [setRejectReasons]: (state, action) => ({
      ...state,
      reasonsData: action.payload.data,
    }),
  }
);
