import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';

export const setTenant = createAction('setTenant');
export const setTenants = createAction('setTenants');

export function fetchTenantInfo(onSuccess = () => {}) {
  return apiAction({
    url: '/tenants/info',
    onSuccess: data => dispatch => {
      dispatch(setTenant(data));
      dispatch(onSuccess(data));
    },
    label: 'tenant',
  });
}

export function editTenantInfo(data) {
  return apiAction({
    url: '/tenants',
    method: 'PUT',
    data,
    onSuccess: fetchTenantInfo,
    showToast: true,
    label: 'editTenantInfo',
  });
}

export function fetchTenants() {
  return apiAction({
    url: '/admin/persons/me',
    onSuccess: setTenants,
    label: 'tenants',
  });
}
