/**
 * axios config using axios.interceptors(doc. https://github.com/axios/axios#interceptors)
 */

import axios from 'axios';
import { cookies } from 'utils/cookies';

let requestUrl = '';

const nonAuthRequiredUrls = ['/login', '/recovery', '/password/recovery'];

// add base url to all requests and token if this available,
// on response with status code 401 dispatch logout action to store
export const httpService = () => {
  // request config
  axios.interceptors.request.use(
    config => {
      config.baseURL =
        process.env.NODE_ENV === 'production'
          ? process.env.REACT_APP_API_URL
          : process.env.REACT_APP_DEV_API_URL;

      requestUrl = config.url;

      const token = cookies.get('_TKN_');
      console.log(
        '🚀 ~ file: apiConfig.js ~ line 26 ~ httpService ~ token',
        token
      );

      if (token && !nonAuthRequiredUrls.includes(requestUrl)) {
        config.headers['X-ADMIN-AUTH-PROTOKEN'] = token;
      }

      return config;
    },
    error => Promise.reject(error)
  );
};
