import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from 'redux-starter-kit';
import apiMiddleware from './middleware/api';
// reducers
import { authReducer } from './reducers/auth';
import { profileReducer, tenantReducer } from './reducers/profile';
import { loadingReducer } from './reducers/loading';
// users
import { usersReducer } from './reducers/users';
// MSK
import { productsReducer } from './reducers/products';
import { reasonsReducer } from './reducers/reasons';
import { appsReducer } from './reducers/apps';
// registry
import { registryReducer } from './reducers/registry';

// Finance
import { financeReducer } from './reducers/finance/balance';

const appReducer = combineReducers({
  authReducer,
  loadings: loadingReducer,
  profileReducer,
  tenantReducer,
  // users
  usersReducer,
  // MSK
  productsReducer,
  reasonsReducer,
  appsReducer,
  // registry
  registryReducer,
  // finance
  financeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return undefined;
  }
  return appReducer(state, action);
};

export default function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), apiMiddleware],
  });
  return store;
}
