import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import {
  filterQueryResolver,
  apiErrorMessageResolver,
  ErrorMessage,
} from 'utils';
import { toast } from 'react-toastify';

export const setUsers = createAction('setUsers');
export const filterUsers = createAction('filterUsers');

export function fetchUsers(props = {}) {
  const { filters = {}, onSuccessCallback, onFailureCallback } = props;
  const query = filterQueryResolver(filters);

  return apiAction({
    url: `/admin/persons?${query}`,
    onSuccess: data => dispatch => {
      dispatch(setUsers(data));
      if (onSuccessCallback) {
        dispatch(onSuccessCallback(data));
      }
    },
    onFailure: error => dispatch => {
      if (onFailureCallback) dispatch(onFailureCallback(error));
    },
    attribute: filters,
    label: 'fetchUsers',
  });
}

export function createUser(data, callback) {
  return apiAction({
    url: '/admin/persons',
    onSuccess: data => dispatch => {
      dispatch(callback(data));
    },
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showToast: true,
    method: 'POST',
    data,
    label: 'userActionLoading',
  });
}

export function editUser(id, data, callback) {
  return apiAction({
    url: `/admin/persons/${id}`,
    method: 'PUT',
    data,
    onSuccess: callback,
    showErrorToast: false,
    onFailure: error => () => {
      const message = ErrorMessage(error);

      toast.error(message);
    },
    showToast: true,
    label: 'userActionLoading',
  });
}

export function deleteUserById(id, callback) {
  return apiAction({
    url: `/admin/persons/${id}`,
    method: 'DELETE',
    onSuccess: callback,
    label: 'fetchUsers',
  });
}
