import React from 'react';
import { Checkbox } from 'antd';

export const ProCheckbox = ({
  fnChange,
  name,
  title = '',
  checked = false,
  disabled,
}) => (
  <>
    <Checkbox
      onChange={e => {
        if (fnChange !== undefined) fnChange(e.target.checked);
      }}
      type="checkbox"
      checked={checked}
      name={name}
      disabled={disabled}
    />
    {`  ${title}`}
  </>
);
