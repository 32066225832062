// Users/ role
export const UserRoles = [
  {
    id: 1,
    value: 1,
    name: 'Admin',
  },
  {
    id: 2,
    value: 2,
    name: 'Satış meneceri',
  },
  {
    id: 3,
    value: 3,
    name: 'Maliyyəçi',
  },
];
