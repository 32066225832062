import { createReducer } from 'redux-starter-kit';
import {
  setQuery,
  setQueryCount,
  setBusinessSector,
  setFeature,
  setTenantFeatue,
  setTenantId,
  setPayments,
  setHistory,
  setFinance,
  setSubscribeUpdateId,
  setSubscribeUpdateIdFinance,
  setSubscribeCount,
  setInvoiceId,
  setDemoDays,
} from 'store/actions/registry';
import { permissionList } from 'config/permissions';

export const registryReducer = createReducer(
  {
    queries: [],
    result: [],
    sectors: [],
    isLoading: false,
    actionLoading: false,
    counts: 0,
    feature: [],
    groupedFeatures: [],
    tenentFeature: [],
    tenantIdData: [],
    payments: [],
    history: [],
    invoices: [],
    subscribes: [],
    subscribesFinance: [],
    subCount: 0,
    invoiceData: [],
    demoDay: [],
  },
  {
    [setQuery]: (state, action) => ({
      ...state,
      queries: action.payload.data,
    }),
    [setFinance]: (state, action) => ({
      ...state,
      invoices: action.payload.data,
    }),
    [setQueryCount]: (state, action) => ({
      ...state,
      counts: action.payload.data,
    }),
    [setBusinessSector]: (state, action) => ({
      ...state,
      sectors: action.payload.data,
    }),
    [setFeature]: (state, action) => {
      const groupedPermissions = Object.values(action.payload.data)?.map(permission => ({
        ...permission,
        ...permissionList[permission.key],
      }));
      return {
        ...state,
        feature:action.payload.data,
        groupedFeatures: groupedPermissions,
      };
    },
    [setTenantFeatue]: (state, action) => ({
      ...state,
      tenentFeature: action.payload.data,
    }),
    [setTenantId]: (state, action) => ({
      ...state,
      tenantIdData: action.payload.data,
    }),
    [setInvoiceId]: (state, action) => ({
      ...state,
      invoiceData: action.payload.data,
    }),
    [setPayments]: (state, action) => ({
      ...state,
      payments: action.payload.data,
    }),
    [setHistory]: (state, action) => ({
      ...state,
      history: action.payload.data,
    }),

    [setSubscribeUpdateId]: (state, action) => ({
      ...state,
      subscribes: action.payload.data,
    }),
    [setSubscribeUpdateIdFinance]: (state, action) => ({
      ...state,
      subscribesFinance: action.payload.data,
    }),
    [setSubscribeCount]: (state, action) => ({
      ...state,
      subCount: action.payload.data,
    }),
    [setDemoDays]: (state, action) => ({
      ...state,
      demoDay: action.payload,
    }),
  }
);
